define("discourse/plugins/discourse-fancy-lists/lib/discourse-markdown/discourse-fancy-lists", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.setup = setup;
  function setup(helper) {
    helper.allowList(["ol[type=*]", "ol[start=*]"]);
    helper.registerPlugin(window.markdownItFancyListPlugin);
  }
});